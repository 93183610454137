$(".js-animsition").animsition({
  inClass: 'overlay-slide-in-right',
  outClass: 'overlay-slide-out-left',
  inDuration: 1000,
  outDuration: 800,
  linkElement: '.animsition-link',
  loading: true,
  loadingParentElement: 'body',
  loadingClass: 'animsition-loading',
  loadingInner: '',
  timeout: false,
  timeoutCountdown: 5000,
  onLoadEvent: true,
  browser: [ 'animation-duration', '-webkit-animation-duration'],
  overlay : true,
  overlayClass : 'animsition-overlay-slide',
  overlayParentElement : 'body',
  transition: function(url){ window.location.href = url; }
});

$(".js-animsition").on('animsition.inEnd', function() {
  APP.showItems();
  APP.searchScroll();
  APP.articlesScroll();
  APP.newsScroll();
  APP.eventsScroll();
  APP.youNeedScroll();
  APP.articleAnimate();
});
